.thumbs-wrapper {
    display: none;
}

.report-container {
    height: 100%;
    width: 100%;
}

.report-container iframe {
    width: 100%;
    height: 100%;
}

html, body, #root {
    height: 100%;
}

.rbc-event {
    background-image: linear-gradient(to right, rgba(197 144 115), rgba(255 253 225), rgba(218 147 88), rgba(255 252 213), rgba(231 184 134));;
    color: #362f78;
    border: 1px solid black;
    font-size: 0.875rem;
    margin: 0.1rem;
    text-transform: capitalize
}

.rbc-header {
    background-color: #f18e05;
    color: #eaf3f9;
    text-align: center;
    padding: 10px 0;
}

.rbc-toolbar {
    font-weight: 600;
    background-color: #354650;
    color: #eaf3f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 20px;
}

.rbc-off-range-bg {
    background-color: rgba(53,70,80,.2);
}

.rbc-btn-group {
    background: white;
    font-size: 1rem;
}

.rbc-active {
    background: #f18e05!important;
    color: white!important;
}

.recharts-legend-item {
    margin-top: 30px!important;
    margin-right: 30px!important;
    margin-left: 30px!important;
}

.recharts-rectangle {
    margin-right: 30px!important;
}

.w-80vw {
    width: 80vw;
}

@media (max-width:480px)  { 
    .rbc-btn-group {
        display: none;
    }
 }